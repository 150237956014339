import React from "react";
import { DebounceInput } from "react-debounce-input";
import useScrollingUp from "../hooks/scroll";
export default function Header({
  onSearch,
  urunler,
  inpref,
  searchStr,
  bulunamayanUrunler,
  data,
}) {
  const isScrollingUp = useScrollingUp();

  console.log(data);
  return (
    <div>
      {data != null ? (
        <>
        {data.empty == 0 ? 
          <div className="  bg-[#FFFFFF]  px-3 pt-[40px] pb-[20px]   ">
            <div className="max-w-[600px] mx-auto">
              <img
                className="block mx-auto w-[70%] md:w-[400px]"
                src={data.site_logo}
                alt=""
              />

{data.empty == 0 ? 
              <div className="flex justify-center  md:justify-between flex-wrap  mt-3 ">
                <a
                  rel="noreferrer"
                  href={data.site_url}
                  target="_blank"
                  className="flex whitespace-nowrap items-center  gap-2 px-3 py-2 rounded-full cursor-pointer text-slate-600 hover:bg-slate-100"
                >
                  <img
                    className="w-[13px] md:w-[17px]"
                    src="/globe.svg"
                    alt=""
                  />
                  <div className="text-sm md:text-lg font-medium ">
                    Web Sitemiz
                  </div>
                </a>

                <a
                  rel="noreferrer"
                  target="_blank"
                  href={`mailto:` + data.site_mail}
                  className="flex order-last whitespace-nowrap items-center gap-2 px-3 py-2 rounded-full cursor-pointer text-slate-600 hover:bg-slate-100"
                >
                  <img
                    className="w-[14px] md:w-[17px]"
                    src="/mail.svg"
                    alt=""
                  />

                  <div className="text-sm md:text-lg font-medium ">
                    {data.site_mail}
                  </div>
                </a>

                <a
                  rel="noreferrer"
                  target="_blank"
                  href={`tel:` + data.site_telefon}
                  className="flex  whitespace-nowrap items-center gap-2 px-3 py-2 rounded-full cursor-pointer text-slate-600 hover:bg-slate-100"
                >
                  <img
                    className="w-[13px] md:w-[17px]"
                    src="/phone.svg"
                    alt=""
                  />
                  <div className="text-sm md:text-lg font-medium ">
                    {data.site_telefon}
                  </div>
                </a>
              </div> : "" }

              <div className="  mt-4 text-center mx-auto ">
                <div className="text-lg  font-medium ">{data.baslik}</div>
                <div className="text-slate-700">{data.mesaj}</div>

                <div className=" text-end text-slate-700">
                  -{data.gonderen}
                </div>
              </div>
            </div>
          </div> : "" }
          <div
            className={`${
              isScrollingUp
                ? "stickyHeader px-3 bg-white pt-3 z-[1000]"
                : "px-3 bg-white pt-3 z-[1000]"
            }`}
          >
            <div className=" max-w-[600px]  mx-auto">
              <div className=" border-4 border-slate-800 shadow shadow-blue-300  hover:shadow cursor-pointer font-medium flex items-center gap-2 bg-[#EBECF0] rounded-full py-2.5 px-4">
                {urunler == null ? (
                  <img
                    className="w-[17px] animate-spin"
                    src="/loading.svg"
                    alt=""
                  />
                ) : (
                  <img
                    className="w-[17px] animation-in"
                    src="/glass.svg"
                    alt=""
                  />
                )}
                <DebounceInput
                  inputRef={inpref}
                  minLength={2}
                  debounceTimeout={300}
                  onChange={onSearch}
                  value={searchStr}
                  className="block w-full bg-transparent outline-none flex-fill placeholder:text-slate-600"
                  type="text"
                  placeholder="Kullandığınız Ürünleri Arayınız"
                />

                <button
                  onClick={() => bulunamayanUrunler()}
                  className="px-3 hover:bg-slate-400  hidden sm:block bg-slate-300 leading-none text-slate-800 py-2  font-medium text-sm py-1 rounded-full  whitespace-nowrap"
                >
                  Ekstra Ürün Talep İçin Tıklayınız
                </button>
              </div>
              <div id="google_translate_element"></div>

            </div>

          </div>

          <div className="bg-white border-b pb-3 pt-1 px-3">
            <div className="max-w-[600px] mx-auto ">
              <button
                onClick={() => bulunamayanUrunler()}
                className="px-3 hover:bg-slate-400 sm:hidden mx-auto flex gap-2 items-center mt-2 text-sm px-4 bg-slate-300 leading-none text-slate-800   font-medium text-sm py-2 rounded-full  whitespace-nowrap"
              >
                <img src="/comment-dots.svg" className="w-[13px]" alt="" />
                Ekstra Ürün Talep İçin Tıklayınız
              </button>

         
            </div>

            <div
            onClick={()=>window.scrollTo(0,0)}
            className={`${
              isScrollingUp
                ? "ok  z-[100] fixed bottom-[40px] right-[40px] bg-blue-500 hover:bg-blue-600 cursor-pointer flex items-center justify-center shadow md:w-[90px] w-[70px] h-[70px] md:h-[90px] rounded-full in"
                : "hidden"
            }`}
          >

            <img src="/arrow.svg" className="w-[20px] md:w-[30px]" alt="" />
          </div>
          </div>
        </>
      ) : (
        <>
          <div className="bg-[#FFFFFF] pt-[40px] px-3 pb-[1px]   ">
            <div className="max-w-[600px] mx-auto">
              <div className="h-[135px] bg-slate-200 animate-pulse mx-auto max-w-[400px]"></div>

              <div className="w-[500px] hidden mt-4 text-center mx-auto ">
                <div className="h-[20px] bg-slate-200 animate-pulse mx-auto w-[400px]"></div>
                <div className="h-[60px] bg-slate-200 animate-pulse mx-auto w-[400px]"></div>
              </div>
            </div>
          </div>
          <div className="sticky px-3 top-[0px]  bg-white py-3 border-b">
            <div className="max-w-[600px] mx-auto">
              <div className="h-[55px] bg-slate-200 animate-pulse mx-auto rounded-full "></div>

              <div className="flex justify-between mt-2 gap-2 ">
                <div className="h-[40px] bg-slate-200 animate-pulse  rounded-full mx-auto flex-fill max-w-[400px]"></div>

                <div className="h-[40px] bg-slate-200 animate-pulse mx-auto rounded-full flex-fill max-w-[400px]"></div>

                <div className="h-[40px] bg-slate-200 animate-pulse mx-auto flex-fill rounded-full max-w-[400px]"></div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
